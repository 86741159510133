.group-workout-header {
  background-color: transparent;
  display: flex;
  justify-content: center;
  color: black;
}

.group-workout-logo {
  display: flex;
  align-items: center;
  padding: 1rem 2rem;
}
