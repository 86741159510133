.boxing-circle-bags {
    position: absolute;
    right: 2px;
    bottom: 70px;
    background-color: white;
    color: black;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    font-family: CanaroBold;
    text-align: center;

    .boxing-circle-bags-text {
        position: relative;
        top: 5px;
    }
}
