.workout-new {
  text-align: center;
  background-color: #000;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%' height='100%'%3E%3Cfilter id='noise'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.8' numOctaves='4' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%' height='100%' opacity='0.4' fill='black' filter='url(%23noise)'/%3E%3C/svg%3E");
  background-size: cover;
  height: 100%;
  min-height: 100dvh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.group-content {
  flex-grow: 1;
}

.round-info {
  color: black;
}

.hidden-audio {
  display: none;
}

.intensity-wrapper {
  color: black;
}

.group-group-challenge-wrapper {
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  height: 75vh;
  .timer, .score {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center; 
  }
}

.hit-grid-container {
  display: grid;
  height: 100%;
  padding: 20px;
  grid-gap: 2rem;
  grid-template-columns: 2fr 1fr;
  grid-template-areas: 'graph timer';

  .graph {
    grid-area: graph;
    align-self: center;
    height: 715px;
  }

  .timer {
    grid-area: timer;
    align-self: center;
  }
}

.intensity-template {
  max-height: 883px;
  width: 100%;
  display: grid;
  grid-gap: 1rem; 
  grid-template-areas: 
  'video video timer'
  'video video timer'
  'video video timer'
  'intensity intensity intensity';
  grid-template-rows: repeat(3, 1fr) auto; /* Adjust as needed */
  ;

  .video {
    grid-area: video;
    padding: 1rem;
    .video-wrapper {
      video {
        // height: 603px;
        // width: 1074px;
        width: 100%;
        height: auto;
        max-width: 100%;
        max-height: 50vh; // or dynamically based on container
        object-fit: contain;
      }
    }
  }

  .timer {
    grid-area: timer;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .intensity {
    grid-area: intensity;
    margin-right: 6px;
    margin-left: 6px;
  }
}

.fullscreen-timer {
  .timer {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.quote-wrapper {
  display: flex;
  flex-direction: row;
  justify-items: space-between;
  min-height: 100%;
  overflow: hidden;
}

.quote-timer {
  flex: 1;
  align-content: center;
}

.quote-text-div {
  flex: 2;
  align-content: center;
  text-align: left;
  min-height: 100%;
}
