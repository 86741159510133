.group-workout-arrow {
  position: absolute; /* So it’s placed relative to the parent container */
  top: 0px;          /* Position above the tracks; adjust as needed */
  left: 50%;
  transform: translateX(-50%); /* Center horizontally by default */
  z-index: 10;        /* Keep it above other elements if needed */
  width: 47px;
  transition: transform 0.5s ease-out;

  svg {
    width: 47px;
  }
}

.group-workout-animation {
  transform: translateX(var(--offset));
  -ms-transform: translateX(var(--offset));
  -moz-transform: translateX(var(--offset));
  -webkit-transform: translateX(var(--offset));
  -o-transform: translateX(var(--offset));
  transition: 'transform 0.5s ease-out';
}
