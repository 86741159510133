.group-group-title {
  font-size: 36px;
  font-weight: 900;
  line-height: 43.2px;
}

.group-group-score {
  font-size: 250px;
  font-weight: 900;
  line-height: 300px;
  .score-number {
    display: inline-block;
    width: 500px; /* Adjust based on the maximum expected digits */
    text-align: right;
  }
  
  .score-icon {
    display: inline-block;
    margin-left: 100px; /* Adjust spacing as needed */
  }
}
