.setup-container {
  box-shadow: none;
  box-shadow: inset 0 0 10px gray !important;
  border: solid 1px gray;
}

.button-style-group {
  background: none !important;
  color: white;
  font-weight: bolder;
  font-size: 2rem !important;
  border-radius: 2.5rem !important;
  padding: 1rem 2rem !important;
  margin: 0 !important;
}

.button-style-bag {
  background: white !important;
  color: black;
  font-weight: bolder;
  font-size: 2rem !important;
  border-radius: 2.5rem !important;
  padding: 1rem 2rem !important;
  margin: 0 !important;
}

.border-style {
  border: solid 2px white !important;
}

.border-style:hover {
  box-shadow: 0 0 10px white;
  cursor: pointer;
}

.item-style {
  gap: 1rem;
  align-content: center;
}

.button-gradient {
  margin-top: 0px !important;
  display: flex;
  align-items: center;
  gap: 1.5rem;
  background: linear-gradient(to right, #ffbd2c, #20ebec) !important;
  align-content: center;
  border-radius: 2.5rem;
  border: none !important;
}

.button-white {
  margin-top: 0px !important;
  display: flex;
  align-items: center;
  gap: 1.5rem;
  align-content: center;
  border-radius: 2.5rem;
  border: none !important;
  background-color: #fff !important;
  color: #000; // sets the text color to black
}

// This rule ensures that any images (e.g., your arrow) within the white button are rendered in black.
// You can adjust the filter if needed depending on your image asset.
.button-white img {
  filter: brightness(0); 
}

.button-gradient:hover {
  box-shadow: 0 0 10px white;
  cursor: pointer;
}

.flex-style {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.group-workout-font-family {
  font-family: 'CanaroBold';
}

.group-setup-duration {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  color: #ffbc2b;
  text-align: center;
  font-weight: bolder;
  font-family: 'CanaroBold';
}

.group-setup-title {
  font-size: 2rem;
  color: #fff;
  font-weight: bolder;
  justify-self: center;
  font-family: 'CanaroBold';
}

.group-setup-subtitle {
  font-size: 1rem;
  color: #fff;
  font-weight: normal;
  justify-self: center;
  font-family: 'CanaroBook';
}

svg.bag-hover {
  :hover {
    cursor: pointer;
    filter: drop-shadow(0 0 4px white);
  }
}

svg.link-hover {
  :hover {
    cursor: pointer;
    filter: drop-shadow(0 0 4px white);
  }
}
