.popup-container {
  box-shadow: none;
  box-shadow: inset 0 0 10px gray !important;
  border: solid 1px gray;
  padding: 4rem !important;
}

.letter-container {
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 3rem;
}

.action-container {
  margin-top: 2rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  gap: 2rem;
  align-items: start;

  svg.bag-hover {
    cursor: pointer;
    height: 250px !important;
  }
}

.action {
  padding: 3rem 1.5rem;
  border: 1px solid gray;
  border-radius: 0.5rem;
}

.middle {
  background-color: rgb(255, 255, 255, 0.14);
}

.label {
  margin-top: 1rem;
  font-size: 1rem;
  font-family: 'CanaroBook';
}
