.barchart-custom {
  background-color: #000;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%' height='100%'%3E%3Cfilter id='noise'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.8' numOctaves='4' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%' height='100%' opacity='0.4' fill='black' filter='url(%23noise)'/%3E%3C/svg%3E");
  background-size: cover;
}

.element-group-hit-challenge-wrapper {
  height: 100%;
}

.barchart-custom {
   padding: '0px 20px 20px 20px';
   border-radius: 20px;
   border: 1px solid #131313;
   box-shadow: 0px 0px 104.9px 37px #FFFFFF0D inset;
}
