.group-intensity-container {
  width: 100%;
  height: 230px;
  margin: 0 auto;
}

.group-intensity-panel {
  display: block;
  width:100%;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 20px;
  border: 1px solid #131313;
  box-shadow: 0px 0px 104.9px 37px #FFFFFF0D inset;
}


.group-intensity-header {
  width: 100%;
  text-align: left;
  margin-bottom: 1rem;
  color: #fff;

  .group-intensity-title {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 19.2px;
    letter-spacing: 2%;
    text-align: center;
    padding-left: 20px;

    margin: 0;

    .group-intensity-icon {
      margin-right: 10px;
      svg {
        width: 21px;
        height: 24px;
      }
    }
  }
}

.group-intensity-meter-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: clamp(15px, 1%, 40px);

  .group-intensity-meter {
    flex: 1 1 auto;
    max-width: 250px;
    text-align: center;
    margin-bottom: 1rem;
  }
}
