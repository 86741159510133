.circular-timer {
  position: relative;

  .svg-circle {
    svg{
      transform: rotate(-90deg);
    }
  }

  .circle-bg {
    fill: none;
    stroke: #2c2c2c;
  }

  .circle-progress {
    fill: none;
    stroke-linecap: round;
    // transition: stroke-dashoffset 0.3s;
  }

  .timer-content {
    font-family: 'CaranoBold';
    position: absolute;
    text-align: center;
    overflow: visible; 

    h1 {
      font-weight: bold;
      color: #fff;
    }

    .switch-info {
      font-family: 'CanaroBold';
      color: #fff;

      .progress-bar-svg {
        display: block;
        margin: 5px auto;
      }

      .current-round {
        font-family: 'CanaroBold';
        font-weight: bold;
        color: #fff;
      }

      .total-rounds {
        font-family: 'CanaroBold';
        font-weight: bold;
        color: #fff;
      }
    }
  }

  .info-container {
    align-items: center;
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-top: 1rem;
  }

  &.fullscreen {
    width: 100vw;
    height: 100vh;

    .timer-content h1 {
      font-size: 5rem;
    }
  }
}

.circular-timer-large { 
  .timer-content {
    left: 50%;
    transform: translate(-50%, -50%);
    .timer-time {
      display: inline-block;
      min-width: 430px;
      text-align: left;
      font-size: 128px;
      line-height: 153.6px;
      margin-bottom: 10px;
    }
    .info-container {
      margin-top: 35px;
      font-size: 64px;
      line-height: 76.8px;
    }
  }
}

.switch {
  .timer-content {
    top: 45%;
  }
}
.no-switch {
  .timer-content {
    top: 50%;
  }
}

.circular-timer-small { 
  .timer-content {
    left: 50%;
    transform: translate(-50%, -50%);
    .timer-time {
      display: inline-block;
      min-width: 300px;
      text-align: left;
      font-size: 94px;
      line-height: 112.8px;
      margin-bottom: 10px;
    }
    .info-container {
      margin-top: 35px;
      font-size: 48px;
      line-height: 57.6px;
    }
  }
}

.circular-timer-medium { 
  .timer-content {
    left: 50%;
    transform: translate(-50%, -50%);
    .timer-time {
      display: inline-block;
      min-width: 300px;
      text-align: left;
      font-size: 94px;
      line-height: 112.8px;
      margin-bottom: 10px;
    }
    .info-container {
      margin-top: 35px;
      font-size: 48px;
      line-height: 57.6px;
    }
  }
}

.circular-timer-mediumplus { 
  .timer-content {
    left: 50%;
    transform: translate(-50%, -50%);
    .timer-time {
      display: inline-block;
      min-width: 300px;
      text-align: left;
      font-size: 94px;
      line-height: 112.8px;
      margin-bottom: 10px;
    }
    .info-container {
      margin-top: 35px;
      font-size: 48px;
      line-height: 57.6px;
    }
  }
}

.circular-timer-mediumlarge { 
  .timer-content {
    left: 50%;
    transform: translate(-50%, -50%);
    .timer-time {
      display: inline-block;
      min-width: 300px;
      text-align: left;
      font-size: 94px;
      line-height: 112.8px;
      margin-bottom: 10px;
    }
    .info-container {
      margin-top: 35px;
      font-size: 48px;
      line-height: 57.6px;
    }
  }
}

