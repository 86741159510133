.half-circle-progress {
  position: relative;

  svg {
    display: block;
  }

  circle {
    transition: fill 0.3s, stroke 0.3s;
  }

  text {
    font-family: Arial, sans-serif;
  }

  .number-circle {
    position: absolute;
    top: calc(50% + 22px); // Adjust as needed for precise positioning
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px; // Diameter of the circle
    height: 40px;
    border-radius: 50%;
    background-color: #fff;
    border: 2px solid #000;
    font-size: 20px; // Adjust font size to fit circle
    font-weight: bold;
    color: #000;
  }

  .arrow {
    position: absolute;

    svg {
      display: block;
    }
  }
}
